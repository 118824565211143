.title {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  .title-heading {
    h1 {
      font-size: 36px;
      letter-spacing: -1px;
      line-height: 1.2;
      font-weight: bold;
      @include media-breakpoint-up(md) {
        font-size: 50px;
      }
    }
    h2 {
      font-size: 22px;
      line-height: 1.2;
      font-weight: normal;
      @include media-breakpoint-up(md) {
        font-size: 31px;
      }
    }
  }
  .title-description {
    p {
      font-size: 19px;
      font-family: $font-family-base;
      color: var(--color-base-text-2);
      text-align: justify;
      @include media-breakpoint-up(md) {
        font-size: 19px;
      }
    }
  }
  .title-heading + .title-description {
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
  .title-social {
    position:absolute;
    margin-top: 30px;
    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }
  }
  .title-badge {
    font-family: $font-family-monospace;
    color: var(--color-primary);
  }
  .title-icon {
    width: 48px;
    margin-bottom: 10px;
    i {
      font-size: 48px;
      color: var(--color-base-900);
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .title-date {
    color: var(--color-base-text-2);
    font-size: 17px;
    font-family: $font-family-base;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .title-image {
    margin-top: 70px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    text-align: center;
    position: relative;
    @include media-breakpoint-up(md) {
      background-position: center center;
    }
    img {
      border-radius: $border-radius;
      max-width: 100%;
      max-height: 500px;
      z-index: 1;
    }
    svg {
      position: absolute;
      z-index: 0;
      width: 500px;
    }
  }
  .title-cols {
    display: flex;
    gap: $grid-gutter-width / 2;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      gap: $grid-gutter-width;
      flex-direction: row;
    }
    .title-col-left {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: center;
    }
    .title-col-right {
      flex-basis: 50%;
    }
    &.title-cols-center {
      flex-direction: column;
      text-align: center;
      align-items: center;
      .title-description {
        width: auto;
      }
      .title-image {
        padding: 0;
        background-image: none;
        padding: 0;
        img {
          max-height: none;
        }
      }
    }
  }
}
